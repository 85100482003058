import Helmet from "react-helmet"
import React from "react"
import OptionalOffer from "@tightrope/optionaloffer"


export default function Optional() {
  return(
    <section>
      <Helmet>
      <style type="text/css">{`
        .footer{ position: relative;
          margin-top: 25px;
        }

        .footer a {
          padding: 0px 1px;
        }
    `}</style>
        <body class="white" />
        <title>Optional Offer- Get Freshy Homepage Newtab- freshysearch.com</title>
      </Helmet>
      <OptionalOffer></OptionalOffer>
    </section>
  )
}
